$link-decoration: none;

$gray-100: #f8f8f8;
$gray-200: #e9e9e9;
$gray-300: #dedede;
$gray-400: #cecece;
$gray-500: #adadad;
$gray-600: #6c6c6c;
$gray-700: #494949;
$gray-800: #343434;
$gray-900: #212121;

//$font-family-sans-serif:
@import "lib/bootstrap/scss/bootstrap";

.contact-link {
  color: $navbar-dark-color;
  text-decoration: if($link-decoration == none, null, none);
  @include transition($nav-link-transition);

  &:hover,
  &:focus {
    //color: $nav-link-hover-color;
    color: $navbar-dark-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  //&.disabled {
  //  color: $nav-link-disabled-color;
  //  color: $navbar-dark-disabled-color;
  //  pointer-events: none;
  //  cursor: default;
  //}
}

@keyframes ahlers-consulting-logo-stroke-fade-in {
  100% {
    opacity: 1;
  }
}

svg.ahlers-consulting-logo g g g:nth-child(4) {
  opacity: 0.05;
  animation: ahlers-consulting-logo-stroke-fade-in 300ms ease 500ms;
  animation-fill-mode: forwards;
}

svg.ahlers-consulting-logo g g g:nth-child(3) {
  opacity: 0.04;
  animation: ahlers-consulting-logo-stroke-fade-in 100ms ease 700ms;
  animation-fill-mode: forwards;
}

svg.ahlers-consulting-logo g g g:nth-child(2) {
  opacity: 0.03;
  animation: ahlers-consulting-logo-stroke-fade-in 300ms ease 1200ms;
  animation-fill-mode: forwards;
}

svg.ahlers-consulting-logo g g g:nth-child(1) {
  opacity: 0.02;
  animation: ahlers-consulting-logo-stroke-fade-in 100ms ease 1400ms;
  animation-fill-mode: forwards;
}

// Without this, the initial size of the grid view is too short, causing an awkward revealing transition.
// TODO: Investigate simplifying this.
body.resume main,
body.resume article,
body.resume article svg.experience-grid-view {
  display: block;
  width: 100%;
  height: 100%;
}

body.resume footer {
  display: none;
}

svg.experience-grid-view g g.experience-brief-view {
  opacity: 0;
}

svg.experience-grid-view g g.experience-brief-view.blank {
  pointer-events: none;
}

svg.experience-grid-view g g.experience-brief-view.content {
  cursor: pointer;
}

svg.experience-grid-view g.revealing g.experience-brief-view.content {
  opacity: 1;
  transition: opacity 500ms ease;
  transition-delay: var(--revealing-transition-delay);
  //animation: reveal-content 250ms ease var(--revealing-transition-delay);
  //animation-fill-mode: forwards;
}

svg.experience-grid-view g.revealing g.experience-brief-view.blank {
  opacity: 0.5;
  transition: opacity 250ms ease;
  transition-delay: var(--revealing-transition-delay);
  //animation: reveal-blank 250ms ease var(--revealing-transition-delay);
  //animation-fill-mode: forwards;
}

svg.experience-grid-view g.presenting g.experience-brief-view.content {
  opacity: 1;
  transition: opacity 250ms ease;
}

svg.experience-grid-view g.presenting g.experience-brief-view.blank {
  opacity: 0.5;
  transition: opacity 250ms ease;
}

svg.experience-grid-view g.focusing g.experience-brief-view.content,
svg.experience-grid-view g.glancing g.experience-brief-view.content {
  opacity: 0.25;
  transition: opacity 250ms ease;
}

svg.experience-grid-view g.focusing g.experience-brief-view.blank,
svg.experience-grid-view g.glancing g.experience-brief-view.blank {
  opacity: 0.1;
  transition: opacity 750ms ease;
}

svg.experience-grid-view g g.experience-brief-focus-view {
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms ease;
}

svg.experience-grid-view g g.experience-brief-focus-view.focused {
  opacity: 1;
}

svg.experience-grid-view g g.experience-brief-focus-view.adjacent {
  opacity: 0.5;
}

svg.experience-grid-view g g.experience-brief-glance-view {
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms ease;
}

svg.experience-grid-view g g.experience-brief-glance-view.glanced {
  opacity: 1;
}

svg.experience-grid-view g g.experience-brief-glance-view label {
  height: 100%;
  padding-left: 60px;
  padding-right: 20px;
  color: $navbar-dark-active-color;
  background-color: $gray-800;
  font-size: 30px;
}

.resume .modal {
  pointer-events: none;
}

.resume .modal.show {
  transition: opacity 250ms ease-out 1000ms;
}

/// Avoids having to meter the class name changes in order to get transitions. Bootstrap's reference scripts set a `display: block` style before adding the show attribute, which is cumbersome in the reactive model.
.modal:not(.show) {
  z-index: -1;
}
